import { render, staticRenderFns } from "./BlueForm.vue?vue&type=template&id=77f8cba2&scoped=true&"
import script from "./BlueForm.vue?vue&type=script&lang=js&"
export * from "./BlueForm.vue?vue&type=script&lang=js&"
import style0 from "./BlueForm.vue?vue&type=style&index=0&id=77f8cba2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77f8cba2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UISpinnerLargeBlueBackground: require('C:/home/site/wwwroot/components/UI/SpinnerLargeBlueBackground.vue').default,UISnackBar: require('C:/home/site/wwwroot/components/UI/SnackBar.vue').default,ConfirmationContactForm: require('C:/home/site/wwwroot/components/confirmation/ContactForm.vue').default})
