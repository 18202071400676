
import { mdiMenuDown } from '@mdi/js'
import sanitize from '~/mixins/sanitize.js'

export default {
  mixins: [sanitize],
  props: {
    location: {
      default: 'Unknown',
      type: String,
    },
  },
  data() {
    return {
      mdiMenuDown,
      confirmation: false,
      spinner: false,
      snackMessage: '',
      snackbar: false,
      name: '',
      phone: '',
      email: '',
      comments: '',
      valid: true,
      selected: '',
      explain: '',
      selectForm: [
        'Recommended by friend or colleague',
        'Google/Search Engine',
        'Social Media',
        'I attended one of your webinars',
        'Other',
      ],

      nameRules: [
        (n) => !!n || 'Full Name is required',
        (n) => (n && !!n.trim()) || 'Provide a Valid Name',
      ],
      commentsRules: [
        (n) => !!n || 'Please add some comments',
        (n) => (n && !!n.trim()) || 'Add valid comments',
      ],
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'Provide a valid E-mail',
      ],
      phoneRules: [
        (v) => !!v || 'Phone Number is required',
        /* eslint-disable no-useless-escape */
        (v) =>
          /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4,5}$/.test(
            v
          ) || 'Provide a valid Phone Number',
      ],
      selectRules: [(v) => !!v || 'Please select one option'],
    }
  },
  computed: {
    selectValidate() {
      if (this.selected !== '') {
        return true
      } else {
        return false
      }
    },
    inputValidate() {
      if (this.valid === true) {
        return true
      } else {
        return false
      }
    },
  },

  methods: {
    async BlueFormRequest() {
      this.spinner = true

      const GoogleAdsParams = this.getGoogleAdsParams()

      const blueFormObject = {
        Name: this.sanitize(this.name),
        Email: this.email,
        Phone: this.phone,
        Comments: this.sanitize(this.comments),
        HowHearAboutUs: this.selected,
        Explanation: this.sanitize(this.explain),
        WebsiteLocation: this.location,
        GoogleAdsInformation: GoogleAdsParams,
      }

      await setTimeout(() => {
        this.$kaiden
          .post(
            'Website/need-a-quote-or-have-more-questions-phone',
            blueFormObject
          )
          .then((res) => {
            if (res.status === 200) {
              this.confirmation = true
              this.spinner = false
              this.$gtm.push({
                event: 'form_submit',
              })
            }
          })
      }, 2500)
    },

    async validateForm() {
      const element = document.getElementsByClassName('blue-form')
      await window.scrollTo({
        top: element[0].offsetTop + 80,
        left: 0,
        behavior: 'smooth',
      })

      if (this.$refs.form.validate()) {
        if (this.inputValidate === true) {
          if (this.selectValidate !== true) {
            this.snackbar = true
            this.snackMessage = 'Please Select How Did Your Hear About Us'
          } else if (this.selected === 'Other' && this.explain === '') {
            this.snackbar = true
            this.snackMessage = 'Please Explain Other'
          } else {
            this.BlueFormRequest()
          }
        }
      } else {
        this.snackbar = true
        this.snackMessage = 'Please fill out the form below'
      }
    },
    snackState() {
      this.snackbar = false
    },
    getGoogleAdsParams() {
      if (process.client) {
        const currentUrl = window.location.href
        const urlParams = new URLSearchParams(currentUrl)
        const source = urlParams.get('utm_source')
        const campaign = urlParams.get('utm_campaign')
        const content = urlParams.get('utm_content')
        const medium = urlParams.get('utm_medium')

        if (source || campaign || content || medium) {
          return `Source: ${source}, Campaign: ${campaign}, Content: ${content}, Medium: ${medium}`
        } else {
          return ''
        }
      }
    },
  },
}
